import { FeatureType } from "@/config/endpoint/types/feature"

export const MENU_ITEMS = [
  { label: '가격', id: 'pricing' },
  { label: '사용자 리뷰', id: 'reviews' },
  { label: 'FAQ', id: 'faq' },
]

export const FEATURE_ITEMS = [
  { label: '공간 정리하기', id: FeatureType.RemoveFurnitures },
  { label: '실내 인테리어 리모델링', id: FeatureType.InteriorRemodeling },
  { label: '스케치 렌더링', id: FeatureType.SketchRendering },
  { label: '평면도 입체화', id: FeatureType.FloorplanRendering },
  { label: '건물 외관 리모델링', id: FeatureType.ExteriorRemodeling }
]

export const FEATURE_USER_ITEMS = [
  { label: '최근 결과물', id: 'recent-results' },
  { label: '즐겨찾기', id: 'favorite-items' }
]

export const ACCOUNT_ITEMS = [
  { label: '계정 설정', id: 'profile' },
  { label: '멤버쉽 정보', id: 'membership' },
  { label: '추천인 코드', id: 'invitation' },
]